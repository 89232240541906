<template>
  <list-template
    :loading="loading"
    :total="total"
    :current-page="page"
    :table-data="tableData"
    :table-config="tableConfig"
    :search-config="searchConfig"
    @onChangePage="changeCurrentPage"
    @onSearch="onSearch"
    @onReset="
      search = null
      searchVal = null
      searchConfig[5].options = searchConfig[6].options = [{ label: '全部', value: -1 }]
    "
    ref="table"
  >
  </list-template>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getDayFromNow } from '@/utils'
const dateArr = [getDayFromNow(-2), getDayFromNow(0)]
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(['page']),
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      loading: true,
      // 搜索框中选定的数据
      search: null,
      // 搜索可用值
      searchVal: null,
      // 数据总量
      total: 0,
      searchConfig: [
        {
          prop: 'student_name',
          placeholder: '搜索学生姓名'
        },
        {
          prop: 'punch_in_status',
          placeholder: '选择打卡状态',
          tag: 'select',
          default: -1,
          options: [
            {
              label: '全部',
              value: -1
            },
            {
              label: '正常',
              value: 1
            },
            {
              label: '迟到',
              value: 2
            },
            {
              label: '未打卡',
              value: 0
            },
            {
              label: '放假',
              value: 3
            }
          ]
        },
        {
          prop: 'date_from_to',
          tag: 'datePicker',
          default: dateArr,
          property: {
            size: 'small',
            style: 'margin-right:20rem;',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          default: -1,
          options: [
            {
              label: '全部',
              value: -1
            }
          ],
          change: (val, search) => this.getClassData(search)
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          default: -1,
          options: [
            {
              label: '全部',
              value: -1
            }
          ],
          change: (val, search) => this.getClassData(search)
        },
        {
          prop: 'teach_class_id',
          placeholder: '筛选教学班',
          tag: 'select',
          default: -1,
          options: [
            {
              label: '全部',
              value: -1
            }
          ]
        },
        {
          prop: 'admin_class_id',
          placeholder: '筛选行政班',
          tag: 'select',
          default: -1,
          options: [
            {
              label: '全部',
              value: -1
            }
          ]
        }
      ],
      tableData: [],
      tableConfig: [
        {
          prop: 'punch_in_status',
          label: '打卡状态',
          render(val) {
            const status = Number(val.punch_in_status)
            const color = status === 1 ? 'green' : status === 2 ? 'red' : '#666'
            let text
            if (status === 1) text = '正常'
            if (status === 2) text = '迟到'
            if (status === 0) text = '未打卡'
            if (status === 3) text = '放假'
            return `<span style="color:${color}">${text}</span>`
          }
        },
        {
          prop: 'student_name',
          label: '学生姓名'
        },
        {
          prop: 'be_late_time',
          label: '迟到时间'
        },
        {
          prop: 'student_no',
          label: '学号'
        },
        {
          prop: 'check_date',
          label: '日期(星期)',
          width: 100
        },
        {
          prop: 'section',
          label: '课节',
          width: 60
        },
        {
          prop: 'class_name',
          label: '教学班名称',
          width: 120
        },
        {
          prop: 'teacher_name',
          label: '教学老师姓名',
          width: '120'
        },
        {
          prop: 'classroom_name',
          label: '上课教室'
        },
        {
          prop: 'punch_in_time',
          label: '打卡时间'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'grade_name',
          label: '年级'
        },
        {
          prop: 'admin_class_name',
          label: '行政班'
        },
        {
          prop: 'admin_teacher_name',
          label: '班主任姓名'
        }
      ]
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    // 校区
    for (let k in this.userInfo.school_arr) {
      this.searchConfig[3].options.push({
        label: this.userInfo.school_arr[k],
        value: k
      })
    }
    this.onSearch({ date_from_to: dateArr })
    // this.getData()
    // 获取年级列表
    this.$utils.commonApi
      .getGradeList()
      .then(res => {
        let data = res.data.data
        this.searchConfig[4].options = [
          {
            label: '全部',
            value: -1
          },
          ...data.map(item => ({
            value: item.id,
            label: item.name
          }))
        ]
      })
      .catch(err => {})

    // 教学班
  },
  methods: {
    ...mapMutations(['setPage']),
    // 获取列表基础数据
    getData() {
      this.loading = true
      this.$_axios
        .post('/attendance/stu-atend-list', { ...this.searchVal, page: this.page })
        .then(res => {
          this.tableData = res.data.data
          let { page, pageCount, pageSize, total } = this.$tools.getPaginationInfo(res.headers)
          this.total = total
        })
        .catch()
        .finally(() => (this.loading = false))
    },
    /**
     * @desc 获取班级数据
     * @param search 搜索框的选定内容
     */
    getClassData(search = {}) {
      const isEmpty = this.$tools.isEmpty
      const { school_id, grade_id, class_type } = search
      const empty = isEmpty([school_id, grade_id], '||')
      // this.searchConfig[5].disabled = this.searchConfig[6].disabled = empty;
      // this.searchConfig[6].disabled = isEmpty([school_id,grade_id,class_type],"||");
      if (!empty) {
        // 获取行政班
        this.$utils.commonApi
          .getClassList(1, school_id, grade_id)
          .then(res => {
            let options = [
              {
                label: '全部',
                value: -1
              },
              ...res.data.data.map(item => ({
                value: item.class_type_id,
                label: item.class_name
              }))
            ]
            this.$set(this.searchConfig, 6, {
              ...this.searchConfig[6],
              options
            })
            // delete search.admin_class_id
            this.$refs.table.setDefaultSearchValue(['admin_class_id'])
            this.$forceUpdate()
          })
          .catch(err => {})
        // 获取教学班
        this.$utils.commonApi
          .getClassList(2, school_id, grade_id)
          .then(res => {
            let options = [
              {
                label: '全部',
                value: -1
              },
              ...res.data.data.map(item => ({
                value: item.class_type_id,
                label: item.class_name
              }))
            ]
            this.$set(this.searchConfig, 5, {
              ...this.searchConfig[5],
              options
            })
            // delete search.teach_class_id
            this.$refs.table.setDefaultSearchValue(['teach_class_id'])
            this.$forceUpdate()
          })
          .catch(err => {})
      }
    },
    // 搜索
    onSearch(val) {
      let search = null
      if (!!val) {
        search = {}
        // 判定数据不为null , 并且不为-1(全部)
        let judge = val => val != null && val !== -1
        let { student_name, punch_in_status, date_from_to, school_id, grade_id, admin_class_id, teach_class_id } = val
        student_name ? (search.student_name = student_name) : null
        judge(punch_in_status) ? (search.punch_in_status = punch_in_status) : null
        date_from_to ? (search.date_from_to = date_from_to.join('~')) : null
        judge(school_id) ? (search.school_id = school_id) : null
        judge(grade_id) ? (search.grade_id = grade_id) : null
        judge(admin_class_id) ? (search.admin_class_id = admin_class_id) : null
        judge(teach_class_id) ? (search.teach_class_id = teach_class_id) : null
      }
      this.setPage(1)
      this.search = val
      this.searchVal = search
      this.getData()
    },
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData()
    }
  }
}
</script>

<style scoped></style>
